import React from "react"
import {Animated} from "react-animated-css";
import { Steps, Step } from "react-step-builder";
import EditProfileStepOne from "./EditProfileStepOne";
import Confirm from "./Confirm";
import EditProfileStepTwo from "./EditProfileStepTwo";
import {Card } from 'react-bootstrap'
import './style.css'

export default function EditProfile(props) {
    return (
        <>
            <Animated animationIn="slideInUp" animationOut="slideInUp" animationInDuration={800} animationOutDuration={800} isVisible={true}>
                <Card className="card-signup">
                    <Card.Body>
                        <Steps>
                            <Step component={EditProfileStepOne}/>
                            <Step component={EditProfileStepTwo} />
                            <Step component={Confirm} />
                        </Steps>
                    </Card.Body>
                </Card>
            </Animated>
        </>
    )
}