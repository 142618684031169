import React, { useState, useEffect, useContext } from "react"
import { globalSettings } from '../../../context/global'
import {Form, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import Loading from '../../Helpers/Screens/Loading/Loading'
import Api from '../../../context/api'
import axios from 'axios'
import './style.css'

export default function EditProfileStepOne (props) {
    const global = useContext(globalSettings)
    const globalTranslates = global.globalValue.Translates['SignUp']
    const globalTranslatesErrors = global.globalValue.Translates['ErrorMessages']
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('')
    const [errorName, setErrorName] = useState('')
    const [errorPasswordQuestion, setErrorPasswordQuestion] = useState('')
    const [errorPasswordAnswer, setErrorPasswordAnswer] = useState('')
    const user = global.globalValue.user
    const [paramsSettings] = useState({
        memberId: user.memberId
    })
    let [userSettings, SetUserSettings] = useState('')

    // Set User Settings to Props
    useEffect(()=>{
        props.state.name = userSettings.name
        props.state.email = userSettings.email
        props.state.passwordQuestion = userSettings.passwordQuestion
        props.state.passwordAnswer = userSettings.passwordAnswer
    },[userSettings])
  
    // Mount Data to Component
    useEffect(()=>{
        fecthUserSettings()
    },[])
    
    // Component Update State
    useEffect(() => {
        setError('')
        setErrorPasswordQuestion('')
        setErrorPasswordAnswer('')
    }, [
        props.state.name,
        props.state.passwordQuestion, 
        props.state.passwordAnswer
    ])
    
    // Handle Validation Form
    const handleValidation = (e) => {
        e.preventDefault()
        if (props.state.name === "" && props.state.email === "" && props.state.passwordQuestion === "" && props.state.passwordAnswer === "" ) {
            setError(globalTranslatesErrors['Required_filed'])
        } else if (props.state.passwordQuestion === "" ) {
            setErrorPasswordQuestion(globalTranslatesErrors['Please_write_security_question'])
        } else if (props.state.passwordAnswer === "" ) {
            setErrorPasswordAnswer(globalTranslatesErrors['Please_write_security_answer'])
        } else if (props.state.name === "") {
            setErrorName(globalTranslatesErrors['Please_write_your_name'])
        } else {
            handleSubmit()
        }
    }
    
    // Handle Fecth User Settings
    const fecthUserSettings = async () => { 
        await axios.get(`${Api.API_USER}?memberId=${paramsSettings.memberId}`)
        .then(res => {
            SetUserSettings(res.data)
        })
        .then(() => {
            setLoading(false)
        })
        .catch(error => {
            //console.log(error)
        })
    }
    
    // Handle Submit Form to Next Step
    const handleSubmit = () => {
        props.next()
    }
    
    return (
        <>
        {   loading ? (
            <Loading/>
                ) : (
                    <>
                        <div className="title-wrapper">
                            <h2 className="title">{globalTranslates['Edit_profile']}</h2>
                            <Link to="/user">
                                <FontAwesomeIcon  className="close-icon" icon={faTimes}/>  
                            </Link>
                        </div>
                        <Form onSubmit={handleValidation}>
                            <Form.Group id="name" className={error ? "error" : errorName ? "error" : "" }>
                                <Form.Label>{globalTranslates['Name']}</Form.Label>
                                <Form.Control value={props.getState('name')} onChange={props.handleChange} name="name" type="text"></Form.Control>
                                <span className="error-msg">{error && error}</span>
                                <span className="error-msg">{errorName && errorName}</span>
                            </Form.Group>
                            <Form.Group id="email" className="disabled">
                                <Form.Label>{globalTranslates['Email']}</Form.Label>
                                <Form.Control value={props.getState('email')} name="email" type="email" disabled={true}></Form.Control>
                            </Form.Group>
                            <Form.Group id="password" className="disabled">
                                <Form.Label>{globalTranslates['Password']}</Form.Label>
                                <Form.Control value={'********'} name="password" disabled={true}></Form.Control>
                            </Form.Group>
                            <Form.Group id="password-confirm" className="disabled">
                                <Form.Label>{globalTranslates['Confirm_password']}</Form.Label>
                                <Form.Control value={'********'} name="passwordConfirm" disabled={true}></Form.Control>
                            </Form.Group>
                            <Form.Group id="password-question" className={error ? "error" : errorPasswordQuestion ? "error" : "" }>
                                <Form.Label>{globalTranslates['Password_question']}</Form.Label>
                                <Form.Control  value={props.getState('passwordQuestion')} onChange={props.handleChange} name="passwordQuestion" type="text" required></Form.Control>
                                <span className="error-msg">{error && error}</span>
                                <span className="error-msg">{errorPasswordQuestion && errorPasswordQuestion}</span>
                            </Form.Group>
                            <Form.Group id="password-answer" className={error ? "error" : errorPasswordAnswer ? "error" : "" }>
                                <Form.Label>{globalTranslates['Password_answwer']}</Form.Label>
                                <Form.Control value={props.getState('passwordAnswer')} onChange={props.handleChange} name="passwordAnswer" type="text" required></Form.Control>
                                <span className="error-msg">{error && error}</span>
                                <span className="error-msg">{errorPasswordAnswer && errorPasswordAnswer}</span>
                            </Form.Group>
                            <Button className="blue-button" type="submit">{globalTranslates['Next']}</Button>
                        </Form>
                        <div className="footer-wrapper">
                            <div className="counter">
                                <span className="active">{props.current}</span>
                                <span> / </span>
                                <span className="total">2</span>
                            </div>
                        </div>
                    </>
                )
            }

        </>
    )
}
