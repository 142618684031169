import React, { useState, useContext } from 'react'
import { globalSettings } from '../../../context/global'
import { NavLink  } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCampground, faIdCardAlt } from '@fortawesome/pro-light-svg-icons'
import './style.css'

export default function Menu(props) {
    const global = useContext(globalSettings)
    let globalTranslates = global.globalValue.Translates['Profile']
    let [menuStatus] = useState(props);
    return (
        <div className='menu-wrapper'>
            {
                menuStatus.isMap ? (
                    <>
                        <div className="active border-wrapper">
                            <NavLink to='/map'>
                                <FontAwesomeIcon className='menu-icon' icon={faCampground}/>  
                                <span>{globalTranslates['Campsites']}</span>
                            </NavLink >
                        </div>
                        <div className="border-wrapper">
                            <NavLink to='/user'>
                                <FontAwesomeIcon className='menu-icon' icon={faIdCardAlt}/>  
                                <span>{globalTranslates['Digital_card']}</span>
                            </NavLink >
                        </div>
                    </>
                    ) : (
                        <>
                            <div className="border-wrapper">
                                <NavLink to='/map'>
                                    <FontAwesomeIcon className='menu-icon' icon={faCampground}/>  
                                    <span>{globalTranslates['Campsites']}</span>
                                </NavLink >
                            </div>
                            <div className="active border-wrapper">
                                <NavLink to='/user'>
                                    <FontAwesomeIcon className='menu-icon' icon={faIdCardAlt}/>  
                                    <span>{globalTranslates['Digital_card']}</span>
                                </NavLink >
                            </div>
                        </>
                )
            }
        </div>
    )
   
}
