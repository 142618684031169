
var baseAPIUrl = window.location.href.toLowerCase().includes("localhost") || window.location.href.toLowerCase().includes("-stg") ? "https://campingcard-stg.shareit.pt" : "https://www.campingcardinternational.com";

const api = {
    // GET
    API_GLOBAL       : baseAPIUrl + "/umbraco/api/cci/Getglobal",
    API_HELP         : baseAPIUrl + "/umbraco/api/cci/GetHelp",
    API_SIGNUP       : baseAPIUrl + "/umbraco/api/cci/GetSignupLists",
    API_CARD         : baseAPIUrl + "/umbraco/api/cci/GetCard",
    API_CLUB         : baseAPIUrl + "/umbraco/api/cci/GetClub",
    API_USER         : baseAPIUrl + "/umbraco/api/cci/GetUser",
    API_CAMPSITES    : baseAPIUrl + "/umbraco/api/cci/GetCampsites",
    // POST
    API_LOGIN        : baseAPIUrl + "/umbraco/api/cci/Login",
    API_ACCOUNT      : baseAPIUrl + "/umbraco/api/cci/Account",
    API_FORGOTPASS   : baseAPIUrl + "/umbraco/api/cci/ForgotPass",
    API_EDITPROFILE  : baseAPIUrl + "/umbraco/api/cci/SaveProfile",
}

export default api

