import React from 'react'
import LoadingImg from '../../../../content/loader.gif'
import './style.css'

export default function Loading() {
    return (
        <div className="loading-screen">
            <figure>
                <img src={LoadingImg} alt="cci-loader-app"/>
            </figure>
        </div>
    )
}
