import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Animated } from "react-animated-css";
import './style.css'

export default function SidebarSignup(props) {
    return (
        <Animated animationIn="slideInRight" animationOut="fadeOut" animationInDuration={800} animationOutDuration={800} isVisible={true}>
            {
                props.type === 'messageError' ? (
                    <div className="card-sidebar-right signup is-error">
                        <span onClick={() => props.closeSideBar()}>
                            <FontAwesomeIcon  className="icon close-icon" icon={faTimes}/>  
                        </span>
                        <h2>{props.title}</h2>
                        <p>{props.text}</p>
                        <FontAwesomeIcon  className="error-icon" icon={faTimesCircle}/>  
                        {props.fields.fields && props.fields.fields.map((element, i) => 
                        <div key={i}>
                            <p>{element.field} : </p>
                            <p>- {element.error}</p>
                            <br></br>
                        </div>
                    )}
                    </div>
                    ) : (
                    <div className="card-sidebar-right signup is-success">
                        <Link to="/login">
                            <FontAwesomeIcon  className="icon close-icon" icon={faTimes}/>  
                        </Link>
                        <h2>{props.title}</h2>
                        <p>{props.text}</p>
                        <FontAwesomeIcon  className="success-icon" icon={faCheckCircle}/>  
                    </div>
                )
            }
        </Animated>
    )
}
