import React, {useState} from 'react'
import Menu from './../Helpers/Menu/Menu'
import Loading from './../Helpers/Screens/Loading/Loading'
import UserProfile from './UserProfile/UserProfile'
import UserDetails from './UserDetails/UserDetails'
import './style.css'

export default function User(props) {
    const [menu] = useState(true);
    const [loading] = useState(false);
    let [menuStatus] = useState({ 
        isMap: false,
        isUser: true
    })

    return (
            <>
            {   loading ? (

                <Loading/>

            ) : (
                <div className="user-wrapper">
                    <UserProfile/>
                    <UserDetails/>

                    { menu ? (<Menu {...menuStatus} />) : ('') }
                </div>
            )}
          </>
    )
}
