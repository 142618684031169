import React from "react"
export default function Confirm(props) {
  return (
    <>
      <p>{props.state.name}</p>
      <p>{props.state.email}</p>
      <p>{props.state.password}</p>
      <p>{props.state.passwordQuestion}</p>
      <p>{props.state.passwordAnswer}</p>
      <p>{props.state.club}</p>
      <p>{props.state.card}</p>
      <p>{props.state.date}</p>
      <p>{props.state.country}</p>
      <p>{props.state.language}</p>
      <p>{props.state.subscrive ? props.state.subscrive.toString() : 'false' }</p>
    </>
  )
}