import React from 'react'
import { Steps, Step } from "react-step-builder";
import LoginForm from "./LoginForm";
import Confirm from "./Confirm";
import './style.css'

export default function Login(props) {
    return (
        <>
            <Steps>
                <Step component={LoginForm}/>
                <Step component={Confirm} />
            </Steps>
        </>
    )
}
