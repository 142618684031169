import React, { useContext } from 'react'
import { globalSettings } from '../../../../context/global'
import DesktopIcon from '../../../../content/svg/desktop-icon.svg'
import './style.css'

export default function Desktop() {
  const global = useContext(globalSettings)
  const globalTranslates = global.globalValue.Translates['SwipeScreen']
 
 
    return ( 
      <div className="desktop-screen">
        <figure>
          <img src={DesktopIcon} alt="desktop-icon" />  
          <legend>{globalTranslates['Please_open_mobile_device']}</legend>
        </figure> 
      </div>
    )
}