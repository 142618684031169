import React from 'react'
import { Steps, Step } from "react-step-builder";
import UserDigitalCard from './UserCard/UserDigitalCard'
import UserHelp from './UserHelp/UserHelp'
import UserContacts from './UserContacts/UserContacts'
import UserNav from './UserNav/UserNav'

export default function UserDetails(props) {
    return (
        <Steps>
            <Step component={UserDigitalCard} />
            <Step component={UserHelp} />
            <Step component={UserContacts} />
            <Step component={UserNav} />
        </Steps>
    )
}
