import React, { useState, useEffect, useContext} from "react"
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { globalSettings } from '../../../context/global'
import { Animated } from "react-animated-css";
import Loading from '../../Helpers/Screens/Loading/Loading'
import SidebarEditProfile from '../../Helpers/Screens/Sidebar/SidebarEditProfile'
import Api from '../../../context/api'
import Helper from './../../../context/helper'
import querystring from 'querystring'
import axios from 'axios'
import './style.css'

export default function EditProfileStepTwo(props) {



    const global = useContext(globalSettings)
    const globalTranslates = global.globalValue.Translates['SignUp']
    const [loading, setLoading] = useState(true)
    const [sidebar, setSidebar] = useState(false)
    const [sidebarStatus, setSidebarStatus] = useState({ 
        type: '',
        title: '',
        text: '',
        fields: [],
        closeSideBar: () =>  setSidebar(false)
    })
    let langList = global['globalValue']['languagesList']
    const [signupSettings, setSignupSettings] = useState('')
    let countriesListSettings = signupSettings.countriesList
    const user =  global.globalValue.user
    const [paramsSettings] = useState({
        memberId: user.memberId
    })
    let [userSettings, SetUserSettings] = useState('')
    
    // Set User Settings to Props
    useEffect(()=>{
        props.state.club = userSettings.club
        props.state.card = userSettings.card
        props.state.expirationDate = userSettings.expirationDate
        props.state.country = userSettings.country
        props.state.countryName = userSettings.countryName
        props.state.language = userSettings.language
        props.state.languageName = userSettings.languageName
    },[userSettings])
    
    // Mount Data to Component
    useEffect(()=>{
         // Handle Fecth Signup Settings
        const fecthSignupSettings = async () => { 
            await axios.get(Api.API_SIGNUP)
            .then(res => {
                setSignupSettings(res.data)
            })
            .then(() => {
                fecthUserSettings()
            })
            .then(() => {
                setLoading(false)
            })
            .catch(error => {
                //console.log(error)
            })
        }
        fecthSignupSettings()
    },[signupSettings])
    
    // Update Data to Component
    useEffect(() => {
        props.state.country = props.state.country
        props.state.countryName = props.state.countryName
        props.state.language = props.state.language
        props.state.languageName = props.state.languageName
    }, [
        props.state.country,
        props.state.countryName, 
        props.state.language, 
        props.state.languageName, 
    ])

    // Handle Fecth User Settings
    const fecthUserSettings = async () => { 
        await axios.get(`${Api.API_USER}?memberId=${paramsSettings.memberId}`)
        .then(res => {
            SetUserSettings(res.data)
        })
        .then(() => {
            setLoading(false)
        })
        .catch(error => {
            //console.log(error)
        })
    }

    // Handle Send Signup User
    const fecthAccountResponse = async (e) => { 
        e.preventDefault()
        let obj = {
            memberId: user.memberId,
            name: props.state.name,
            card : props.state.card,
            club : props.state.club,
            expirationDate : props.state.expirationDate,
            email: props.state.email,
            passwordQuestion: props.state.passwordQuestion,
            passwordAnswer: props.state.passwordAnswer,
            country: props.state.country,
            language: props.state.language,
            subscrive: props.state.subscrive
        }
        await axios.post(Api.API_EDITPROFILE, querystring.stringify(obj), Helper.headers)
        .then(res => {
            setLoading(false)
            setSidebar(true)
            setSidebarStatus({
                type: 'Success',
                title: res.data.messageSuccess.title,
                text: res.data.messageSuccess.text
            })
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.data.messageError) {
                    setLoading(false)
                    setSidebar(true)
                    setSidebarStatus({
                        type: 'messageError',
                        title: error.response.data.messageError.title,
                        text: error.response.data.messageError.text
                    })
                }
            } 
        })
    }

    return (
        <>
            {   loading ? (
                <Loading/>
                ) : (
                    <>
                        <div className="card-signup-wrapper">
                            <Animated animationIn="slideInRight" animationOut="fadeOut" animationInDuration={800} animationOutDuration={800} isVisible={true}>
                                <>
                                    <div className="title-wrapper">
                                        <h2 className="title">{globalTranslates['Edit_profile']}</h2>
                                        <Link to="/user">
                                            <FontAwesomeIcon  className="close-icon" icon={faTimes}/>  
                                        </Link>
                                    </div>
                                    <Form onSubmit={fecthAccountResponse}>
                                        <Form.Group id="club" className="disabled">
                                            <Form.Label>{globalTranslates['Club']}</Form.Label>
                                            <Form.Control  placeholder={userSettings.club} value={userSettings.club} name="club" type="text" disabled={true}></Form.Control>
                                        </Form.Group>
                                        <Form.Group id="card" className="disabled">
                                            <Form.Label>{globalTranslates['Card_number']}</Form.Label>
                                            <Form.Control  placeholder={userSettings.card} value={userSettings.card} name="card" type="text" disabled={true}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="disabled">
                                            <Form.Label>{globalTranslates['Expiration_date']}</Form.Label>
                                            <Form.Control placeholder={userSettings.expirationDate} value={userSettings.expirationDate} type="text" disabled={true}></Form.Control>
                                        </Form.Group>
                                        <Form.Group id="country" className="form-select">
                                            <Form.Label>{globalTranslates['Country']}</Form.Label>
                                            <Form.Control value={props.getState('country')} name="country" as="select" size="sm" onChange={props.handleChange} custom>
                                                <option value={props.getState('country')}>{userSettings.countryName}</option>
                                                {countriesListSettings.map((option) => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                            </Form.Control>
                                            <FontAwesomeIcon  className="form-icon right-align" icon={faChevronDown}/>  
                                        </Form.Group>
                                        <Form.Group id="language" className="form-select">
                                            <Form.Label>{globalTranslates['Language']}</Form.Label>
                                            <Form.Control value={props.getState('language')} name="language" as="select" size="sm" onChange={props.handleChange} custom>
                                            <option value={userSettings.language} hidden sellected>{userSettings.languageName}</option>
                                                {langList.map((option) => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                            </Form.Control>
                                            <FontAwesomeIcon  className="form-icon right-align" icon={faChevronDown}/>  
                                        </Form.Group>
                                        <Form.Group controlId="subscrive" className="form-checkbox">
                                            <Form.Check name="subscrive" type="checkbox" label={globalTranslates['Receive_news_from_cci']} onChange={props.handleChange}/>
                                        </Form.Group>
                                        <Button className="blue-button" type="submit">{globalTranslates['Register']}</Button>
                                    </Form>
                                    <div className="footer-wrapper">
                                        <span className="back-link" onClick={props.prev}>
                                            <FontAwesomeIcon  className="form-icon left-align" icon={faChevronLeft}/>  
                                            <small>{globalTranslates['Back']}</small>
                                        </span>
                                        <div className="counter">
                                            <span className="active">{props.current}</span>
                                            <span className="active"> / </span>
                                            <span className="active total">2</span>
                                        </div>
                                    </div>
                                </>
                            </Animated>
                            {   sidebar ? (
                                    <div className="card-sidebar-wrapper">
                                        <SidebarEditProfile {...sidebarStatus}/>
                                    </div>
                            ) : ('')}
                    </div>                
                </>
            )}
        </>
    )
}