import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { globalSettings } from '../../../context/global'
import { Animated } from "react-animated-css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// , faMoneyBillWaveAlt, faUtensilsAlt, faBook, faCaravan, faBaby, faTshirt, faVolleyballBall, faMobile, faSuitcaseRolling
import { faTimes, faPhone, faArrowLeft, faMapMarked} from '@fortawesome/pro-light-svg-icons'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation} from 'swiper'
import './style.css'
import IMAGE_EXAMPLE_SMALL from '../../../content/list-item-small-no-image.png'
import IMAGE_EXAMPLE_MEDIUM from '../../../content/list-item-medium.jpg'

SwiperCore.use([ Pagination, Navigation])

export default function MapDetail(props) {
    const global = useContext(globalSettings)
    const globalTranslates = global.globalValue.Translates['MapDetail']
    const [details, setDetails] = useState(false)
    let images = props.image
    
    //console.log(props);

    //let disconts = props.disconts

    // Handle open full detail
    const OpenDetails = () => {
        setDetails(true)
    }
    
    
    //  Handle type discont
    /*  @ Disconts type:
        1    -   bank         : faMoneyBillWaveAlt   : 
        2    -   restaurant  : faUtensilsAlt        : 
        3    -   books        : faBook               : 
        4    -   caravans     : faCaravan            : 
        5    -   children     : faBaby               : 
        6    -   clothes      : faTshirt             : 
        7    -   sport        : faVolleyballBall     : 
        8    -   tech         : faMobile             : 
        9    -   travel       : faSuitcaseRolling    : 
    */
    // const DiscontType = (discont) => {
    //     let icon = ''
    //     if (discont === 'bank') {
    //         icon = faMoneyBillWaveAlt
    //     } else if (discont === 'restaurant') {
    //         icon = faUtensilsAlt
    //     }  else if (discont === 'books') {
    //         icon = faBook
    //     } else if (discont === 'caravans') {
    //         icon = faCaravan
    //     } else if (discont === 'children') {
    //         icon = faBaby
    //     } else if (discont === 'clothes') {
    //         icon = faTshirt
    //     } else if (discont === 'sport') {
    //         icon = faVolleyballBall
    //     } else if (discont === 'tech') {
    //         icon = faMobile
    //     } else if (discont === 'travel') {
    //         icon = faSuitcaseRolling
    //     }
    //     return icon
    // }

    return (
        <div className={`map-detail-screen ${details ? 'is-detail' : '' }`}>
            <div onClick={OpenDetails}>
            {
                details ? (
                    <Animated className="animated-wrapper" animationIn="slideInUp" animationOut="fadeOut" animationInDuration={800} animationOutDuration={800}>
                        <figure>
                        <Swiper  
                            spaceBetween={0}
                            slidesPerView={1}
                            pagination={images !== null && images.length && images.length > 1 ? { clickable: true } : false}
                            navigation={images !== null && images.length && images.length > 1 ? { nextEl: '.button-next', prevEl: '.button-prev' } : false}>
                            {images !== null && images.length ? (images.map(item => (
                                <SwiperSlide>
                                    {item !== '' && item !== null && item.length ? (
                                        <>
                                        <img key={item} src={item} alt={item} />
                                        </>
                                    ):(
                                    <>
                                    <img src={IMAGE_EXAMPLE_SMALL}/>
                                    </>
                                    )}
                                </SwiperSlide>
                            ))):(
                                <SwiperSlide>
                                    <img src={IMAGE_EXAMPLE_SMALL}/>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <legend>
                            <div className="legend-wrapper">
                                <FontAwesomeIcon className="icon back-icon" icon={faArrowLeft} onClick={props.close}/>
                                <div className="map" onClick={props.close}>
                                    <FontAwesomeIcon className="icon" icon={faMapMarked}/>
                                    <span>{globalTranslates['Map']}</span>
                                </div>
                                {images !== null && images.length && images.length > 1 ? (
                                    <>
                                    <div className="navigation">
                                        <span className="button-prev">
                                            <FontAwesomeIcon className="icon prev-icon" icon={faChevronLeft}/>
                                        </span>
                                        <span className="button-next">
                                            <FontAwesomeIcon className="icon next-icon" icon={faChevronRight}/>
                                        </span>
                                    </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </legend>
                    </figure>
                    <div className="detail-wrapper">
                        <div className="title-container">
                            <div className="title-wrapper">
                                <h2 dangerouslySetInnerHTML={{__html: !props.name ? '' : props.name }}></h2>
                                <span dangerouslySetInnerHTML={{__html: !props.address ? '' : props.address }}></span>
                            </div>
                            <a target="_blank" rel="noopener noreferrer" href={`tel:${!props.phone ? '' : props.phone}`}>
                                <FontAwesomeIcon  className="icon" icon={faPhone}/>
                            </a>
                        </div>
                        <div className="link-container">
                            <div className="discount-label-container">
                                {
                                    props.hs_discount !== '' && props.hs_discount !== '0' && props.hs_discount !== null ? (
                                        <div className="discount-wrapper green">
                                            <span>{`-${props.hs_discount}%`}</span>
                                        </div>
                                    ) : (<></>)
                                }
                                {
                                    props.ls_discount !== '' && props.ls_discount !== '0' && props.ls_discount !== null ? (
                                        <div className="discount-wrapper blue">
                                            <span>{`-${props.ls_discount}%`}</span>
                                        </div>
                                    ) : (<></>)
                                }
                            </div>

                            {
                                props.booking_url && props.booking_url != '' ? (
                                <Link to={props.booking_url} className="green-button button">{globalTranslates['Book_now']}</Link>
                                ) : (<></>)
                            }
                            
                        </div>
                        <div className="description-container">
                            <p>{props.description}</p>    
                        </div>
                    </div>

                    {
                        /*
                        <div className="discount-container">
                            <h2>Discounts</h2>
                            <div className="discount-list">
                            {disconts && disconts.map(discont => (
                                <div className="discount-item" key={discont.id}>
                                    <div className="icon-wrapper">
                                        <div className="discount-wrapper blue">
                                            <span>{`-${discont.data_discont_discont}%`}</span>
                                        </div>
                                        <FontAwesomeIcon className={`icon type-icon ${discont.data_discont_type}`} icon={DiscontType(discont.data_discont_type)} />
                                    </div>
                                    <h3>{discont.data_disconts_name}</h3>
                                </div>
                            ))}
                            </div>
                        </div>
                        */
                    }

                    {
                        props.amenities != null && props.amenities.length ? (
                            <div className="amenities-container">
                                <h2>Amenities</h2>
                                <div className="amenities-list">
                                    {
                                        props.amenities.map(amenity => (
                                            <div className="amenity-item">
                                                <div className="icon-wrapper">
                                                    <img src={amenity.iconUrl} alt={amenity.name} />
                                                </div>
                                                <h3>{amenity.name}</h3>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ):(<></>)
                    }

                    </Animated>
                ) : (
                    <>    
                        <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={800} animationOutDuration={800}>
                        <figure>
                            {images === null || !images.length ? (
                                <>
                                <img src={IMAGE_EXAMPLE_SMALL}/>
                                </>
                            ) :
                            (images && images.filter((e, i) => i === 0).map((item, i) => (
                                <img key={i} src={item} alt={i} />
                            )))}
                            <div className="close-wrapper">
                                <FontAwesomeIcon  className="icon close-icon" icon={faTimes} onClick={props.close}/>  
                            </div>
                        </figure>
                        <div className="detail-wrapper">
                            <div className="title-container">
                                <div className="title-wrapper">
                                    <h2>{!props.name ? '' : props.name}</h2>
                                    <span dangerouslySetInnerHTML={{__html: !props.address ? '' : props.address }}></span>
                                </div>
                                <a target="_blank" rel="noopener noreferrer" href={`tel:${!props.phone ? '' : props.phone}`}>
                                    <FontAwesomeIcon  className="icon" icon={faPhone}/>
                                </a>
                            </div>
                        </div>
                        </Animated>
                    </>    
                )}
            </div>
        </div>
    )
}
