import React, { useContext } from 'react'
import { globalSettings } from '../../../../context/global'
import { Link } from 'react-router-dom';

export default function NotFound() {
  const global = useContext(globalSettings)
  const globalTranslates = global.globalValue.Translates['NotFoundScreen']
  return (
    <>
      <p>{globalTranslates['Page_not_fond']}</p>
      <Link to="/">{globalTranslates['Go_back']}</Link>
    </>
  )
}
