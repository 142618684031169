import React, { useState, useEffect } from 'react'
import { globalSettings } from "./context/global"
import { Switch, Route, Redirect, HashRouter, useLocation} from 'react-router-dom'
import Loading from './components/Helpers/Screens/Loading/Loading'
import NotFound from './components/Helpers/Screens/NotFound/NotFound'
import Onboard from './components/Helpers/Screens/Onboard/Onboard'
import Desktop from './components/Helpers/Screens/Desktop/Desktop'
import Swipe from './components/Helpers/Screens/Swipe/Swipe'
import Login from './components/Authentication/Login/Login'
import Signup from './components/Authentication/Signup/Signup'
import EditProfile from './components/Authentication/EditProfile/EditProfile'
import ForgotPassword from './components/Authentication/ForgotPassword/ForgotPassword'
import Map from './components/Map/Map'
import User from './components/User/User'
import UserCard from './components/User/UserDetails/UserCard/UserCard'
import axios from 'axios'
import Api from './context/api'
import './App.css'
import Helper from './context/helper'

export default function App() {

  const [globalValue, setGlobalValue] = useState('')
  const [globalCampsites, setGlobalCampsites] = useState('')

  const [loading, setLoading] = useState(true)
  let [isMobile, setMobile] = useState(false)
  isMobile = typeof window.orientation !== 'undefined'
  let [screenType, setScreenType] = useState('')
  let [screenPath, setScreenPath] = useState('')

  useEffect(()=>{

    if(typeof window.screen.orientation != 'undefined'){
      window.addEventListener('orientationchange', () => {
        let currentPath = window.location.hash;
        setScreenPath(currentPath)

        let screenOrientation = window.screen.orientation
        //alert(JSON.stringify(Helper.handleObject(screenOrientation)));
        setScreenType(screenOrientation.type)        
      }) 
    }
    else
    {
      //Old Browsers
      let mql = window.matchMedia("(orientation: portrait)");
      mql.addListener(function(m) {
        //alert(JSON.stringify(Helper.handleObject(m)));
          let currentPath = window.location.hash;
          setScreenPath(currentPath)
          if(m.matches) {
              setScreenType('portrait-primary')
          }
          else 
          {
            setScreenType('landscape-primary')
          }
      });
    }

  }, [screenType])

  useEffect(()=>{
     if (isMobile !== 'undefined') {
       setMobile(false)
     } else if (isMobile === 'undefined') {
       setMobile(true)
     }
  }, [isMobile])

  // Component Update State
  useEffect(() => {
    fecthGlobalSettings()
  }, [])
  
  // Handle Fecth Global Settings
  const fecthGlobalSettings = async () => { 

    await axios.get(`${Api.API_GLOBAL}?lang=EN`)
    .then(res => {
      setGlobalValue(res.data)
    })
    .then(() => {
      setLoading(false)
    })
    .catch(error => {
      //console.log(error)
    })

    await axios.get(`${Api.API_CAMPSITES}`)
    .then(res => {
      setGlobalCampsites(res.data.campsites)
    })
    .then(() => {
        setLoading(false)
    })
    .catch(error => {
        //console.log(error)
    })

  }
  
  return (
    <>
      <globalSettings.Provider value={{globalValue, setGlobalValue, globalCampsites, setGlobalCampsites}}>

        { loading ? (
          <Loading/>
        ) : (
          !isMobile ? (
            <Desktop></Desktop>
          ) : (
            screenType === 'landscape-primary' || screenType === 'landscape-secondary' ?  (
              screenPath === '#/user' ? (
                <UserCard></UserCard>
              ) : (
                <Swipe></Swipe>
              )
            ) : (
              <HashRouter>
                <Switch>
                  <Route exact path="/" component={Onboard}/>
                  <Route path="/login" component={Login}/>
                  <Route path="/signup" component={Signup}/>
                  <Route path="/edit-profile" component={EditProfile}/>
                  <Route path="/forgot-pass" component={ForgotPassword}/>
                  <Route path="/map" component={Map} />
                  <Route path="/user" component={User} />
                  <Route path="/404" component={NotFound} />
                  <Redirect to="/" component={NotFound} />
                </Switch>
              </HashRouter>
            )
          ) 
        )}
      </globalSettings.Provider>
    </>
  )
}
