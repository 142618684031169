import React, { useContext, useState} from 'react'
import { globalSettings } from '../../../context/global'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import UserImg from './../../../content/svg/user.svg'
import './style.css'
import { faSignOutAlt } from '@fortawesome/pro-regular-svg-icons/faSignOutAlt'

export default function UserProfile() {
    
    const global = useContext(globalSettings)
    const user =  global.globalValue.user
    const [paramsSettings] = useState({
        name: user.name
    })
    return (
        <div className="user-profile">
            <Link className="edit-user" to='/edit-profile' >
                <div>
                    <FontAwesomeIcon className='edit-user-icon' icon={faEdit}/>  
                </div>
            </Link >
            <Link className="sign-out" to='/' >
                <div>
                    <FontAwesomeIcon className='sign-out-alt' icon={faSignOutAlt}/>  
                </div>
            </Link >
            <figure className="user-img">
                <img src={UserImg} alt="img-user" />
            </figure>
            <h2 className="user-name">{paramsSettings.name && paramsSettings.name}</h2>
            <small>Profile</small>
        </div>
    )
}
