import React, { useContext, useState, useEffect } from 'react'
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api'
import { globalSettings } from '../../context/global'
import Loading from './../Helpers/Screens/Loading/Loading'
import Menu from './../Helpers/Menu/Menu'
import MapDetail from './MapDetail/MapDetail'
import MapSearch from './MapSearch/MapSearch'
import MapList from './MapList/MapList'
import Api from '../../context/api'
import axios from 'axios'
import './style.css'

export default function Map(props) {

  const global = useContext(globalSettings)

  const globalTranslates = global.globalValue.Translates['Map']

  const [ loading, setLoading ] = useState(true)
  const [ visible, setVisible ] = useState(false)
  const [ selected, setSelected ] = useState({})

  const [ campSitesSettings, setCampSitesSettings ] = useState({
    campsites:'',
    results: ''
  })
  let campSites = campSitesSettings.campsites

  let [ menuStatus ] = useState({ 
    isMap: true,
    isUser: false
  })

  const [ searchSettings, setSearchSettings ] = useState({
    isMenu: true,
    showList: false,
    listType: '',
    isAllowScreen: false,
    isFilterScreen: false,
    isAllow: false,
    mapZoom: 5,
    defaultLocation: { lat: 47.08758503, lng: 8.72265625 },
    campsites: campSites
  })
    
  
  // Handle Goolgle Maps Api WorkPlace
  const SetWorkPlace = () => {
    let key = ''
    
    if (window.location.hostname.includes('localhost') || window.location.href.toLowerCase().includes("-stg")) {
      key = 'AIzaSyCe305GfiEp4iqGUpO5FJLrFwIO_r7MA8A'
    } else {
      key = 'AIzaSyACakQ6u3tpzMsLc57Yw1pdkTJvZ3YCqMc' 
    }

    return key
  }
  
  // Map Default Configs
  const mapConfigs = {
    disableDefaultUI: true,
    mapStyles: {height: "100vh", width: "100%"},
  }
  
  // Component Update State
  
  useEffect(() => {
    
    // Handle Fecth Camp Sites Settings
    
    if(campSites === '' || campSites === null || typeof campSites === 'undefined' ){
      const fecthCampSites = async () => { 

        if(typeof global.globalCampsites === 'undefined' || global.globalCampsites === '' || global.globalCampsites === null){
          await axios.get(`${Api.API_CAMPSITES}`)
          .then(res => {
            console.log(res.data.campsites)
            setCampSitesSettings({
              campsites: res.data.campsites,
              results : res.data.campsites
            })
          })
          .then(() => {
              setLoading(false)
          })
          .catch(error => {
              //console.log(error)
          })
        }
        else
        {
          setCampSitesSettings({
            campsites: global.globalCampsites,
            results : global.globalCampsites
          })
          setLoading(false)
        }

      }
      fecthCampSites();

    }

  }, [campSitesSettings])

  // Handle Select Item
  const onSelect = item => {
    setSelected({
      id : item.id,
      name : item.name,
      type : item.type,
      icon: item.icon,
      country : item.country,
      latitude : item.latitude,
      longitude : item.longitude,
      image : item.images,
      amenities: item.amenities,
      discount : item.discount,
      hs_discount : item.hs_discount,
      ls_discount : item.ls_discount,
      description : item.description,
      address : item.address,
      phone : item.phone,
      url : item.url,
      close: function () {
        setSelected({})
        setVisible(false)
      }
    })
    setVisible(true)
  }
  
  //console.log("Map Search Settings");
  //console.log(searchSettings);

  if(searchSettings.campsites !== '' && searchSettings.campsites !== null && searchSettings.campsites !== undefined ){
    //console.log('set');
    //console.log(searchSettings.campsites);
    //campSites = searchSettings.campsites;
  }

  //console.log('campSites');
  //console.log(campSites);

  const results = searchSettings.campsites !== '' && searchSettings.campsites !== null && searchSettings.campsites !== undefined ? searchSettings.campsites : campSites;

  return (
    
    <>
      { loading ? (
        <Loading/>
      ) : ( 
        <>
          <div className="map-wrapper">
              <MapSearch setSearchSettings={setSearchSettings} {...campSitesSettings}/>
              
              <LoadScript googleMapsApiKey={SetWorkPlace()}>
              <GoogleMap
                className="google-maps"
                mapContainerStyle={mapConfigs.mapStyles}
                zoom={searchSettings.mapZoom}
                center={searchSettings.defaultLocation}
                options={mapConfigs}
                
                /*
                onLoad={map => {
                  const bounds = new window.google.maps.LatLngBounds();
                  map.fitBounds(bounds);
                }}
                */
                >

                {searchSettings.isAllow ? (
                  
                  <Marker 
                      key='me'
                      position={searchSettings.defaultLocation} 
                      icon={{
                        url: globalTranslates["Default_icon"]
                      }}
                      onClick={ () => {}} 
                      />
                
                ) : (<></>)}

                  <MarkerClusterer>
                  
                  {(clusterer) =>

                    campSites.map((location,i) => (

                      <Marker 
                        key={i} 
                        clusterer={clusterer}
                        position={{
                          lat: location.latitude,
                          lng: location.longitude
                        }} 
                        icon={{
                          url: location.icon,
                        }}
                        onClick={ () => {
                          onSelect(location)
                        }} 
                        />
                    ))
                  }
                </MarkerClusterer>
                </GoogleMap>
              </LoadScript>
            </div>
          { searchSettings.showList ? (<MapList setDetail={onSelect} {...searchSettings}/>) : ('') }
          { visible ? (<MapDetail {...selected}/>) : ('') }
          { searchSettings.isMenu ? (<Menu {...menuStatus} />) : ('') }
        </>
      )}
    </>
  )
}
