import React, { useContext } from 'react'
import { globalSettings } from '../../../../context/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCardAlt, faQuestion, faPhone } from '@fortawesome/pro-light-svg-icons'
import './style.css'

export default function UserNav(props) {

    const global = useContext(globalSettings)
    const globalTranslates = Object(global.globalValue.Translates['Profile'])
    const goToCard = () => { props.jump(1) }
    const goToHelp = () => { props.jump(2) }
    const goToContacts = () => { props.jump(3) }

    return (
        <>
            <ul className="nav">
                <li onClick={goToCard} className={ props.step.order === 1  ? 'active' : ''}>
                    <FontAwesomeIcon className='user-detail-icon' icon={faIdCardAlt}/>  
                    <span>{globalTranslates['Digital_card']}</span>
                </li>
                <li onClick={goToHelp} className={ props.step.order === 2  ? 'active' : ''}>
                    <FontAwesomeIcon className='user-detail-icon' icon={faQuestion}/>  
                    <span>{globalTranslates['Help']}</span>
                </li>
                <li onClick={goToContacts} className={ props.step.order === 3  ? 'active' : ''}>
                    <FontAwesomeIcon className='user-detail-icon' icon={faPhone}/>  
                    <span>{globalTranslates['Contacts']}</span>
                </li>
            </ul>
        </>
    )
}
