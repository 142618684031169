import React, { useContext, useState, useEffect} from 'react'
import { globalSettings } from '../../../context/global'
import { Form, Button } from 'react-bootstrap'
import { Animated } from "react-animated-css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch, faSlidersH, faLocationArrow, faChevronDown } from '@fortawesome/pro-light-svg-icons'
import Helper from './../../../context/helper'
import './style.css'
import { text } from '@fortawesome/fontawesome-svg-core'

export default function MapSearch(props) {

    const global = useContext(globalSettings)

    const globalTranslates = global.globalValue.Translates['Map']

    const [allowScreen, setAllowScreen] = useState(false)
    const [filterScreen, setFilterScreen] = useState(false)
    const [noResultsScreen, setNoResultsScreen] = useState(false)
    const [geoError, setGeoError] = useState(false)
    const [geoErrorMessage, setGeoErrorMessage] = useState('')
    
    const [campSites, setCampsites] = useState(props.campsites)

    let selectedCountries = []
    let selectedDiscount = []
    let selectedCategory = []

    let selectedNames = []
    let [campSitesNames] = useState(Helper.filterArrays(campSites, ['name'], selectedNames))

    let [campSitesCountries] = useState(Helper.filterArrays(campSites, ['country'], selectedCountries))
    let [campSitesCategories] = useState(Helper.filterArrays(campSites, ['type'], selectedCategory))
    let [campSitesDisconts] = useState(Helper.filterArrays(campSites, ['hs_discount'], selectedDiscount).sort((a,b)=>a-b))
    
    const [country, setCountry] = useState('Country')
    const [category, setCategory] = useState('Category')
    const [discount, setDiscount] = useState('Discount')

    let [searchSubmit, setSearchSubmit] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState([]);
    // const [search, setSearch] = useState('')

    //console.log('Countries');
    //console.log(campSites);
    //console.log(campSitesCountries);

    //console.log(global);

    useEffect(() => {
        if(searchTerm !== undefined){
            
            const regex = new RegExp(`${searchTerm}`, 'gi')
            const results = selectedNames.filter(name =>
                name !== undefined && name.toLowerCase().includes(searchTerm.toLowerCase())
            )

            setSearchResults(campSites.filter(element => results.includes(element.name)))
            searchSubmit = campSites.find(element => element.name === results[0])
            setSearchSubmit(searchSubmit)

            //console.log('update search - name: '+ searchResults[0]);
            //console.log(searchSubmit);

        }
    }, [searchTerm])

    // Handle Countries Change
    // const handleCountriesChanges = () => {
    //     let selectedCountry = props.campSites.filter(item => item.data_country === country)
    //     campSitesCountries = filterArrays(selectedCountry, ['data_type'], selectedCategory)
    //     campSitesCountries = filterArrays(selectedCountry, ['data_discount'], selectedDiscount)
    //     setCampSitesCategories(selectedCategory)
    //     setCampSitesDisconts(selectedDiscount)
    // }
    
    // Handle Allow State
    const handleAllowState = () => {
        if (navigator.geolocation) {
            
            navigator.geolocation.getCurrentPosition(function(position) {

                //alert(JSON.stringify(Helper.handleObject(position)));

                let pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                } 
                props.setSearchSettings({
                    isAllow: true,
                    defaultLocation: pos,
                    mapZoom: 8,
                    showList: true,
                    listType: 'near',
                    isMenu: true,
                    campsites:props.campsites
                })
                setAllowScreen(false);
            },function(positionError){
                //alert(JSON.stringify(Helper.handleObject(positionError)));
                setAllowScreen(false);
                setGeoError(true);
                setGeoErrorMessage(positionError.message);
            })
        }
        else{
            setAllowScreen(false);
        }

    }

    // Handle Remove Allow State
    const handleRemoveAllowState = () => {
        setAllowScreen(false)
        setGeoError(false)
        props.setSearchSettings({
            isAllow: false,
            defaultLocation: { lat: 47.08758503, lng: 8.72265625 }
        })
    }

    // Handle Remove No Results
    const handleRemoveNoResultsState = () => {
        setNoResultsScreen(false)
    }
 
    // Handle Allow Screen
    const handleAllowScreen = () => {
        setAllowScreen(allowScreen ? false : true)
        props.setSearchSettings({
            isAllowScreen: allowScreen ? false : true,
            isMenu: true
        })
    }
    
    // Handle Filter Screen
    const handleFilterScreen = () => {
        
        setFilterScreen(filterScreen ? false : true)
        props.setSearchSettings({
            showList: false,
            isFilterScreen: filterScreen ? false : true,
            isMenu: filterScreen ? true : false,
            filters: {
                country: country,
                category: category,
                discount: discount
            }
        })
    }
    
    // Handle Search Input 
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    }
    
    // Handle Submit Search
    const handleSubmitSearch = () => {

        //console.log('search');
        //console.log(searchSubmit);
        //console.log('search results');
        //console.log(searchResults);


        if(searchResults.length){

            let pos = {
                lat: searchSubmit.latitude,
                lng: searchSubmit.longitude
            } 

            props.setSearchSettings({
                showList: true,
                isFilterScreen: false,
                listType: 'search',
                isMenu: true,
                loaded: false,
                campsites:searchResults,
                defaultLocation: pos,
                mapZoom: 12
            })
        }
        else
        {
            setNoResultsScreen(true);
        }

    }

    const handleSearchTerm = event => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            handleSubmitSearch();
        }
    };
    
    // Handle Submit Filters
    const handleSubmitFilters = () => {
        
        //console.log(country)
        //console.log(category)
        //console.log(discount)

        let results = campSites;
        let noFilters = true;

        if(country !== '' && country !== 'Country'){
            results = results.filter(element =>
                element.country !== undefined && element.country !== null && element.country !== '' && element.country.toLowerCase().includes(country.toLowerCase())
            )
            noFilters = false;
        }

        if(category !== '' && category !== 'Category'){
            results = results.filter(element =>
                element.type !== undefined && element.type !== null && element.type !== '' && element.type.toLowerCase().includes(category.toLowerCase())
            )
            noFilters = false;
        }

        if(discount !== '' && discount !== 'Discount'){
            results = results.filter(element =>
                element.hs_discount !== undefined && element.hs_discount !== null && element.hs_discount !== '' && element.hs_discount.toLowerCase().includes(discount.toLowerCase())
            )
            noFilters = false;
        }
        
        //setSearchResults(results)

        //console.log('Filters');
        //console.log(results);

        if(results !== undefined && results.length){

            let country_area = {
                lat: results[0].latitude,
                lng: results[0].longitude
            };
            let country_zoom = 6;

            if(noFilters){
                country_area = { lat: 47.08758503, lng: 8.72265625 };
                country_zoom = 5;
            }

            setFilterScreen(false)
            props.setSearchSettings({
                showList: true,
                listType: 'search',
                isMenu: true,
                defaultLocation: country_area,
                mapZoom: country_zoom,
                loaded: false,
                campsites:results,
                filters: {
                    country: country,
                    category: category,
                    discount: discount
                }
            })            
        }

    }

    // // Handle Clean Filters
    const handleCleanFilters = () => {
        //setCampsites('')
        setCountry('')
        setCategory('')
        setDiscount('')
    }
 
    return (
        <>
            <div className="map-search">
                <Form.Group className="search-input-wrapper" >
                    <Button className="search-input-button" onClick={handleSubmitSearch}>
                        <FontAwesomeIcon className="icon" icon={faSearch}/>
                    </Button>
                    <Form.Control className="search-input" type="text" placeholder={`${globalTranslates['Search']}`} value={searchTerm} onKeyDown={handleSearchTerm} onChange={handleSearch}></Form.Control>
                </Form.Group>
                <div className={`button filter-button ${filterScreen ? 'is-filter-screen' : '' }  ${allowScreen ? 'is-allow-screen' : '' }`} onClick={handleFilterScreen}>
                    <FontAwesomeIcon className="icon" icon={faSlidersH}/>
                </div>
                { filterScreen ? (
                        <div className={`button allow-button ${allowScreen ? 'is-allow-screen' : '' }`} onClick={handleFilterScreen}>
                            <FontAwesomeIcon className="icon" icon={faTimes}/>
                        </div>   
                    ) : (
                        <div className={`button allow-button ${allowScreen ? 'is-allow-screen' : '' }`} onClick={handleAllowScreen}>
                            {
                                allowScreen ? (
                                    <FontAwesomeIcon className="icon" icon={faTimes}/>
                                ) : (
                                    <FontAwesomeIcon className="icon" icon={faLocationArrow}/>
                                )
                            }
                        </div>   
                    )
                }
            </div>
            {
                allowScreen ? (

                    <Animated className="allow-location-screen" animationIn="slideInUp" animationOut="fadeOut" animationInDuration={800} animationOutDuration={800}>
                        <div className="message-wrapper">
                            <div className="wrapper">
                                <h2>{globalTranslates['Allow_app_access']}</h2>
                                <div className="button-wrapper">
                                    <span className="link" onClick={handleRemoveAllowState}>
                                        <FontAwesomeIcon className="icon close-icon" icon={faTimes}/>
                                        {globalTranslates['Not_allow']}
                                    </span>
                                    <span className="button green-button" onClick={handleAllowState}>{globalTranslates['Allow']}</span>
                                </div>
                            </div>
                        </div>
                    </Animated>
                ) : 
                ('')
            }

            {
                geoError ? (

                    <Animated className="allow-location-screen" animationIn="slideInUp" animationOut="fadeOut" animationInDuration={800} animationOutDuration={800}>
                        <div className="message-wrapper">
                            <div className="wrapper">
                                <h2 style={{ textAlign: 'center' }} >{geoErrorMessage}</h2>
                                <div className="button-wrapper">
                                    <span className="button green-button" onClick={handleRemoveAllowState}>Ok</span>
                                </div>
                            </div>
                        </div>
                    </Animated>
                ) : 
                ('')
            }

            {
                noResultsScreen ? (

                    <Animated className="allow-location-screen" animationIn="slideInUp" animationOut="fadeOut" animationInDuration={800} animationOutDuration={800}>
                        <div className="message-wrapper">
                            <div className="wrapper">
                                <h2>No Results</h2>
                                <div className="button-wrapper">
                                    <span className="button green-button" onClick={handleRemoveNoResultsState}>OK</span>
                                </div>
                            </div>
                        </div>
                    </Animated>
                ) : 
                ('')
            }

            {
                filterScreen ? (
                    <Animated className="filter-screen" animationIn="slideInUp" animationOut="fadeOut" animationInDuration={800} animationOutDuration={800}>
                        <div className="filter-screen-wrapper">
                            <h2>{globalTranslates['Redefine_your_search']}</h2>
                            <div className="selects-wrapper">
                                <Form className="filter-form">

                                    <Form.Group id="country" className="form-select">
                                        <Form.Control value={country} name="country" as="select" size="sm" custom onChange={event => setCountry(event.target.value)}>
                                            <option hidden>{globalTranslates['Country']}</option>
                                            {campSitesCountries.map((option, i) => (
                                                <option key={i} value={option}>{option}</option>
                                            ))}
                                        </Form.Control>
                                        <FontAwesomeIcon className="form-icon right-align" icon={faChevronDown}/>  
                                    </Form.Group>

                                    <Form.Group id="category" className="form-select">
                                        <Form.Control value={category} name="category" as="select" size="sm" custom onChange={event => setCategory(event.target.value)}>
                                            <option hidden>{globalTranslates['Category']}</option>
                                            {campSitesCategories.map((option, i) => (
                                                <option key={i} value={option}>{option}</option>
                                            ))}
                                        </Form.Control>
                                        <FontAwesomeIcon className="form-icon right-align" icon={faChevronDown}/>  
                                    </Form.Group>

                                    <Form.Group id="discount" className="form-select">
                                        <Form.Control value={discount} name="discount" as="select" size="sm" custom onChange={event => setDiscount(event.target.value)}>
                                            <option hidden>{globalTranslates['Discount']}</option>
                                            {campSitesDisconts.map((option, i) => (
                                                <option key={i} value={option}>{option}</option>
                                            ))}
                                        </Form.Control>
                                        <FontAwesomeIcon className="form-icon right-align" icon={faChevronDown}/>  
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                        <div className="submit-wrapper">
                            <div className="link clean-button" onClick={handleCleanFilters}>
                                <FontAwesomeIcon className="icon close-icon" icon={faTimes}/>
                                <small>{globalTranslates['Clear_all']}</small>
                            </div>
                            <span className="button green-button" onClick={handleSubmitFilters}>{globalTranslates['Apply']}</span> 
                        </div>
                    </Animated>
                ) : 
                ('')
            }
        </>
    )
}