import React, { useContext, useRef, useEffect } from 'react'
import { globalSettings } from '../../../../context/global'
import { Link } from 'react-router-dom'
import LogoImg from '../../../../content/svg/cci-logo.svg'
import './style.css'

export default function Onboard() {
    const global = useContext(globalSettings)
    const globalTranslates = global.globalValue.Translates['OnboardScreen']

    const linkRef = useRef(null);

    useEffect(() => {
      const timeout = setTimeout(() => {
        // Trigger the click event on the Link after the timeout
        linkRef.current.click();
      }, 6000); // Timeout duration in milliseconds
  
      // Clear the timeout when the component unmounts or changes
      return () => clearTimeout(timeout);
    }, []);

    return (
        <>
            <Link to="/login" ref={linkRef}>
                <div className="onboard-screen">
                    <figure className="logo">
                        <img src={LogoImg} alt="cci-card-app"/>
                    </figure>
                    <h1 className="text">{globalTranslates['Get_discounts_in']} {globalTranslates['CampsitesTotal']} {globalTranslates['Campsites_in']} {globalTranslates['CountriesTotal']} {globalTranslates['Countries']} </h1>
                </div>
            </Link>
        </>
    )
}
