import React, { useContext, useState } from 'react'
import { globalSettings } from '../../../../../context/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import FrontImg from '../../../../../content/card-front.png'
import './style.css'

export default function FrontCard (props) {
    const global = useContext(globalSettings)
    const globalTranslates = global.globalValue.Translates['DigitalCard']
    const [cardSettings] = useState(props)
    const card = cardSettings['cardUserList']
    return (
        <div className="front-card">
            <div className="image-background" style={{backgroundImage: `url(${FrontImg})`}}>
                <div className="button-next">
                    {
                        props.index === 1 ? ('') : (
                            <div className="icon-wrapper">
                                <FontAwesomeIcon  className="sync-icon" icon={faSyncAlt}/>  
                            </div>
                        )
                    }
                </div>
                <figure>
                    <img className="image-small-logo" src={card['club_logo']} alt="club-logo"/>
                </figure>
                <div className="card-content-wrapper">
                    <div className="card-content card-content-left">
                        <div className="card-content-item card-number">
                           <small>{card['number']}</small>
                        </div> 
                        <div className="card-content-item card-name">
                            <label>{globalTranslates['Name']}:</label>
                            <small>{card['name']}</small>
                        </div> 
                        <div className="card-content-item card-address">
                            <label>{globalTranslates['Address']}:</label>    
                            <small>{card['address']}</small>
                        </div> 
                        <div className="card-content-item card-birth">
                            <label>{globalTranslates['Birth']}:</label>    
                            <small>{card['birth_date']} {card['country']}</small>
                        </div>
                        <div className="card-content-item card-passport">
                            <label>{globalTranslates['Passport']} nº:</label>    
                            <small>{card['passport']}</small>
                        </div> 
                        <div className="card-content-item card-issue">
                            <label>{globalTranslates['Issue']}:</label>    
                            <small>{card['issue_date']} {card['issue_place']}</small>
                        </div> 
                        <div className="card-content-item card-member-number">
                            <label>{globalTranslates['Member']} nº:</label>    
                            <small>{card['member']}</small>
                        </div>
                    </div>
                    <div className="card-content card-content-rigth">
                        <div className="card-content-item card-nacinality">
                            <label>{globalTranslates['Nacionality']}:</label>    
                            <small>{card['country']}</small>
                        </div>
                        <div className="card-content-item card-validity">
                            <label> {globalTranslates['Validity']}:</label>    
                            <small>{card['validity']}</small>
                        </div> 
                    </div>
                </div>
            </div>
       </div>
    )
}