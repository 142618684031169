import React, { useContext, useState, useEffect, useRef } from 'react'
import { globalSettings } from '../../../context/global'
import { Link, useHistory } from 'react-router-dom'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faUnlockAlt, faEyeSlash, faFlag } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../Helpers/Screens/Loading/Loading'
import axios from 'axios'
import querystring from 'querystring'
import Helper from './../../../context/helper'
import Api from './../../../context/api'
import './style.css'

export default function LoginForm(props) {
    const loaded = useRef(false)
    const history = useHistory()
    const global = useContext(globalSettings)
    //console.log(global)
    const globalTranslates = global.globalValue.Translates['Login']
    const globalTranslatesErrors = global.globalValue.Translates['ErrorMessages']
    const [passwordShown, setPasswordShown] = useState(false)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const [errorEmail, setErrorEmail] = useState('')
    const [errorPassword, setErrorPassword] = useState('')
    const [errorLanguage, setErrorLanguage] = useState('')
    let langList = global['globalValue']['languagesList']
    let [langCode, setLangCode] = useState('')
    
    // Component Update State
    useEffect(() => {
        setError('')
        setErrorPassword('')
        setErrorEmail('')
        setErrorLanguage('')
    },[
        props.state.email,
        props.state.password,
        props.state.language,
    ])
    
    // Component Mount & State
    useEffect(() => {
        if (loaded.current) {
            setCodeLanguage(props.state.language)
            fecthLanguageSettings()
        } else {
            loaded.current = true;
        }
    }, [props.state.language])
    
    // Handle Validation Form
    const handleValidation = (e) => {
        e.preventDefault()
        let patternEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
        let patternPassword = new RegExp(/^.{6,}$/)
        if (props.state.email === "" && props.state.password === "") {
            setErrorEmail(globalTranslatesErrors['Required_filed'])
            setErrorPassword(globalTranslatesErrors['Required_filed'])
            setErrorLanguage(globalTranslatesErrors['Required_filed'])
        } else if (props.state.email === "") {
            setErrorEmail(globalTranslatesErrors['Please_write_your_email'])
        } else if (props.state.password === "") {
            setErrorPassword(globalTranslatesErrors['Please_write_your_password'])
        } else if (props.state.language === "") {
            setErrorLanguage(globalTranslatesErrors['Please_select_your_language'])
        } else if (!patternEmail.test(props.state.email)) {
            setErrorEmail(globalTranslatesErrors['Please_write_valid_email'])
        } else if (!patternPassword.test(props.state.password)) {
            setErrorPassword(globalTranslatesErrors['Minimum_6_digits'])
        } else {
            setLoading(true)
            fecthUsersResponse()
        }
    }

    // Handle POST Signup Settings
    const fecthUsersResponse = async () => { 
        await axios.post(Api.API_LOGIN, querystring.stringify(props.state), Helper.headers)
        .then(res => {
            let newObject = Helper.addToObject(global.globalValue, 'user', res.data);
            global.setGlobalValue(newObject)
        })
        .then(res => {
            setLoading(false)
            history.push('/map')
        })
        .catch(function (error) {
            setLoading(false)
            if (error.response) {
                setError(error.response.data.messageError.text)
            } 
        })
    }

    // Handle Language Settings
    const setCodeLanguage = (code) => {
        if (code ===  '399') {
            langCode = 'EN'
        } else if (code ===  '400') {
            langCode = 'FR'
        } else if (code ===  '401') {
            langCode = 'DE'
        } else if (code ===  '402') {
            langCode = 'IT'
        } else if (code ===  '403') {
            langCode = 'ES'
        } else if (code ===  '404') {
            langCode = 'NL'
        } else if (code ===  '405') {
            langCode = 'SK'
        }
        setLangCode(langCode)
    }

    // Handle Fecth Language Settings
    const fecthLanguageSettings = async () => { 
        await axios.get(`${Api.API_GLOBAL}?lang=${langCode}`)
        .then(res => {
            let newObject = Helper.addToObject(res.data, 'lang',langCode);
            global.setGlobalValue(newObject)
        })
        .catch(error => {
          //console.log(error)
        })
    }

    return (
        <>
            { loading ? ( <Loading/>
            ) : (
                <Card className={`card-login ${errorEmail && errorPassword && errorLanguage ? 'is-block' : '' }`}>
                    <Card.Body>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <h2 className="title w-100 text-center">{globalTranslates['Welcome_back']}</h2>
                        <Form className="login-form" onSubmit={handleValidation}>
                            <Form.Group id="email" className={errorEmail ? "error" : ""}>
                                <Form.Label>{globalTranslates['Email']}</Form.Label>
                                <FontAwesomeIcon className="form-icon" icon={faUserCircle}/>
                                <Form.Control value={props.getState('email')} name="email" type="email" onChange={props.handleChange} required></Form.Control>
                                <span className="error-msg">{errorEmail && errorEmail}</span>
                            </Form.Group>
                            <Form.Group id="password" className={errorPassword ? "error" : ""}>
                                <Form.Label>{globalTranslates['Password']}</Form.Label>
                                <FontAwesomeIcon className="form-icon" icon={faUnlockAlt} />
                                <Form.Control value={props.getState('password')} name="password"  type={passwordShown ? "text" : "password"} className="password" onChange={props.handleChange} ></Form.Control>
                                <FontAwesomeIcon onClick={() => Helper.toggleVisiblity(passwordShown,setPasswordShown)} className="form-icon right-align" icon={faEyeSlash}/>
                                <span className="error-msg">{errorPassword && errorPassword}</span>
                            </Form.Group>
                            <Form.Group id="language" className={errorLanguage ? "error form-select" : " form-select"}>
                                <Form.Label>{globalTranslates['Language']}</Form.Label>
                                <FontAwesomeIcon className="form-icon" icon={faFlag}/>
                                <Form.Control value={props.getState('language')} name="language" as="select" size="sm" onChange={props.handleChange} custom>
                                    <option hidden>{globalTranslates['Select']}</option>
                                    {langList && langList.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Control>
                                <FontAwesomeIcon className="form-icon right-align" icon={faChevronDown}/>  
                                <span className="error-msg">{errorLanguage && errorLanguage}</span>
                            </Form.Group>
                            <Button className="green-button" type="submit">{globalTranslates['Login']}</Button>
                            <Link className="link" to="/forgot-pass">{globalTranslates['Forgot_password']}</Link>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <div>
                            <small>{globalTranslates['Dont_have_an_account']}</small>
                            <Link className="blue-button" to="/signup">{globalTranslates['Register_now']}</Link>
                        </div>
                    </Card.Footer>
                </Card>
            )}
        </>
    )
}
