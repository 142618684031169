import React, { useState, useEffect, useContext} from "react"
import { Link } from 'react-router-dom'
import { Form, Button, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faChevronDown, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { globalSettings } from '../../../context/global'
import { Animated } from "react-animated-css";
import Loading from '../../Helpers/Screens/Loading/Loading'
import SidebarSignup from '../../Helpers/Screens/Sidebar/SidebarSignup'
import Api from './../../../context/api'
import Helper from './../../../context/helper'
import querystring from 'querystring'
import axios from 'axios'
import './style.css'

export default function SignupStepTwo(props) {
    const global = useContext(globalSettings)
    const globalTranslates = global.globalValue.Translates['SignUp']
    const globalTranslatesErrors = global.globalValue.Translates['ErrorMessages']
    const [signupSettings, setSignupSettings] = useState('')
    const [loading, setLoading] = useState(true)
    const [errorServer, setErrorServer] = useState('')
    const [error, setError] = useState(props)
    const [errorClub, setErrorClub] = useState('')
    const [errorCardNumber, setErrorCardNumber] = useState('')
    const [errorExpirationDate, setErrorExpirationDate] = useState('')
    const [errorCountry, setErrorCountry] = useState('')
    const [errorLanguage, setLanguageError] = useState('')
    const [sidebar, setSidebar] = useState(false)
    const [sidebarStatus, setSidebarStatus] = useState({ 
        type: '',
        title: '',
        text: '',
        fields: [],
        closeSideBar: () =>  setSidebar(false)
    })
    let langList = global['globalValue']['languagesList']
    let clubsListSettings = signupSettings.clubsList
    let countriesListSettings = signupSettings.countriesList
    
    // Component Mount State
    useEffect(() => {
        fecthSignupSettings()
    }, [])
    
    // Component Update State
    useEffect(() => {
        setErrorServer('')
        setError('')
        setErrorClub('')
        setErrorCardNumber('')
        setErrorExpirationDate('')
        setErrorCountry('')
        setLanguageError('')
    },[ 
        props.state.club,
        props.state.card,
        props.state.expirationDate,
        props.state.country,
        props.state.language
    ])
    
    // Handle Validation Form
    const handleValidation = (e) => {
        e.preventDefault()
        setLoading(true)
        if (props.state.club === "" &&  props.state.card === "" && props.state.expirationDate === "" && props.state.country === "" &&   props.state.language === "" ) {
            setError(globalTranslatesErrors['Required_filed'])
        } else if (props.state.card === "") {
            setErrorCardNumber(globalTranslatesErrors['Please_write_your_card_number'])
        } else if (props.state.expirationDate === "") {
            setErrorExpirationDate(globalTranslatesErrors['Please_select_a_date'])
        } else if (props.state.country === "") {
            setErrorCountry(globalTranslatesErrors['Please_select_your_country'])
        } else if (props.state.language === "") {
            setLanguageError(globalTranslatesErrors['Please_select_your_language'])
        } else {
            fecthAccountResponse()
        }
    }
    
    // Handle Fecth Signup Settings
    const fecthSignupSettings = async () => { 
        await axios.get(Api.API_SIGNUP)
        .then(res => {
            setSignupSettings(res.data)
        })
        .then(() => {
            setLoading(false)
        })
        .catch(error => {
            //console.log(error)
        })
    }
 
    // Handle Send Signup User
    const fecthAccountResponse = async () => { 
        await axios.post(Api.API_ACCOUNT, querystring.stringify(props.state), Helper.headers )
        .then(res => {
            setLoading(false)
            setSidebar(true)
            setSidebarStatus({
                type: 'Success',
                title: res.data.messageSuccess.title,
                text: res.data.messageSuccess.text
            })
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.data.messageError.fields) {
                    error.response.data.messageError.fields.forEach(element => {
                        switch(element.field) {
                            case 'cardNumber': 
                                setLoading(false)
                                setSidebar(true)
                                setSidebarStatus({
                                    type: 'messageError',
                                    fields: error.response.data.messageError,
                                    title: error.response.data.messageError.title,
                                    text: error.response.data.messageError.text,
                                    closeSideBar: () =>  setSidebar(false)
                                })
                                setErrorCardNumber(element.error)
                                break;
                            case 'cardExpirationDate':
                                setLoading(false)
                                setSidebar(true)
                                setSidebarStatus({
                                    type: 'messageError',
                                    fields: error.response.data.messageError,
                                    title: error.response.data.messageError.title,
                                    text: error.response.data.messageError.text,
                                    closeSideBar: () => setSidebar(false)
                                })
                                setErrorExpirationDate(element.error)
                                break;
                            case 'duplicatedEmail':
                                    setLoading(false)
                                    setSidebar(true)
                                    setErrorCardNumber('')
                                    setErrorExpirationDate('')
                                    setSidebarStatus({
                                        type: 'messageError',
                                        fields: error.response.data.messageError,
                                        title: error.response.data.messageError.title,
                                        text: error.response.data.messageError.text,
                                        closeSideBar: () => props.prev()
                                })
                                break;
                            default:
                        }
                    })
                }
                //console.log(error.response)
            } 
        })
  
    }

    return (
        <>
            {   loading ? (
                <Loading/>
            ) : (
                <>
                    <div className="card-signup-wrapper">
                        <Animated animationIn="slideInRight" animationOut="fadeOut" animationInDuration={800} animationOutDuration={800} isVisible={true}>
                            <>
                                {errorServer && <Alert variant="danger">{errorServer}</Alert>}
                                <div className="title-wrapper">
                                    <h2 className="title">{globalTranslates['Sign_up']}</h2>
                                    <Link to="/login">
                                        <FontAwesomeIcon  className="close-icon" icon={faTimes}/>  
                                    </Link>
                                </div>
                                <Form.Group id="club" className={error ? "form-select error" : errorClub ? "error form-select" : "form-select" }>
                                    <Form.Label>{globalTranslates['Club']}</Form.Label>
                                    <Form.Control value={props.getState('club')} name="club" as="select" size="sm" onChange={props.handleChange} custom>
                                        <option hidden>{globalTranslates['Select']}</option>
                                        {clubsListSettings.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Control>
                                    <FontAwesomeIcon className="form-icon right-align" icon={faChevronDown}/>  
                                    <span className="error-msg">{error && error}</span>
                                    <span className="error-msg">{errorClub && errorClub}</span>
                                </Form.Group>
                                <Form.Group id="card" className={error ? "error" : errorCardNumber ? "error" : "" }>
                                    <Form.Label>{globalTranslates['Card_number']}</Form.Label>
                                    <Form.Control value={props.getState('card')} name="card" type="text" onChange={props.handleChange}></Form.Control>
                                    <span className="error-msg">{error && error}</span>
                                    <span className="error-msg">{errorCardNumber && errorCardNumber}</span>
                                </Form.Group>
                                <Form.Group id="date" className={error ? "form-date error" : errorExpirationDate ? "error form-date" : "form-date" }>
                                    <Form.Label>{globalTranslates['Expiration_date']}</Form.Label>
                                    <Form.Control value={props.getState('date')} name="date" className="date" type="date" onChange={props.handleChange}></Form.Control>
                                    <span className="error-msg">{error && error}</span>
                                    <span className="error-msg">{errorExpirationDate && errorExpirationDate}</span>
                                </Form.Group>
                                <Form.Group id="country" className={error ? "form-select error" : errorCountry ? "error form-select" : "form-select" }>
                                    <Form.Label>{globalTranslates['Country']}</Form.Label>
                                    <Form.Control  value={props.getState('country')} name="country" as="select" size="sm" onChange={props.handleChange} custom>
                                        <option hidden>{globalTranslates['Select']}</option>
                                        {countriesListSettings.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                    </Form.Control>
                                    <FontAwesomeIcon  className="form-icon right-align" icon={faChevronDown}/>  
                                    <span className="error-msg">{error && error}</span>
                                    <span className="error-msg">{errorCountry && errorCountry}</span>
                                </Form.Group>
                                <Form.Group id="language" className={error ? "form-select error" : errorLanguage ? "error form-select" : "form-select" }>
                                    <Form.Label>{globalTranslates['Language']}</Form.Label>
                                    <Form.Control value={props.getState('language')} name="language" as="select" size="sm" onChange={props.handleChange} custom>
                                        <option hidden>{globalTranslates['Select']}</option>
                                        {langList.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Control>
                                    <FontAwesomeIcon  className="form-icon right-align" icon={faChevronDown}/>  
                                    <span className="error-msg">{error && error}</span>
                                    <span className="error-msg">{errorLanguage && errorLanguage}</span>
                                </Form.Group>
                                <Form.Group controlId="subscrive" className="form-checkbox">
                                    <Form.Check name="subscrive" type="checkbox" label={globalTranslates['Receive_news_from_cci']} onChange={props.handleChange}/>
                                </Form.Group>
                                <Button className="blue-button" onClick={handleValidation}>{globalTranslates['Register']}</Button>
                                <div className="footer-wrapper">
                                    <span className="back-link" onClick={props.prev}>
                                        <FontAwesomeIcon  className="form-icon left-align" icon={faChevronLeft}/>  
                                        <small>{globalTranslates['Back']}</small>
                                    </span>
                                    <div className="counter">
                                        <span className="active">{props.current}</span>
                                        <span className="active"> / </span>
                                        <span className="active total">2</span>
                                    </div>
                                </div>
                            </>
                        </Animated>
                        { sidebar ? (
                            <div className="card-sidebar-wrapper">
                                <SidebarSignup {...sidebarStatus}/>
                            </div>
                        ) : ('')}
                    </div>                
                </>
            )}
        </>
    )
}
