import React, { useState, useRef, useContext} from 'react'
import { globalSettings } from '../../../context/global'
import { Link } from 'react-router-dom'
import { Form, Button, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../Helpers/Screens/Loading/Loading'
import SidebarForgotPass from '../../Helpers/Screens/Sidebar/SidebarForgotPass'
import Helper from './../../../context/helper'
import querystring from 'querystring'
import Api from './../../../context/api'
import axios from 'axios'
import './style.css'

export default function ForgotPassword(props) {
    const global = useContext(globalSettings)
    const globalTranslates = global.globalValue.Translates['ForgotPassword']
    const [loading, setLoading] = useState(false)
    const [sidebar, setSidebar] = useState(false)
    const [sidebarStatus, setSidebarStatus] = useState({
        type: '',
        title: '',
        text: ''
    })
    const [errorEmail, setErrorEmail] = useState('')
    const emailRef = useRef()

    // Handle Validation Form
    function handleValidation(e) {
        e.preventDefault()
         let patternEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
         // Email Validation
         if (emailRef.current.value === "") {
             setErrorEmail('Please write your email!')
             // Email String verification
            } else if (!patternEmail.test(emailRef.current.value)) {
                setErrorEmail('Please write a Valid Email!')
            } else {
                setLoading(true)
                fecthEmailResponse()
        }
    }
   
    // Handle Send Email to Api
    const fecthEmailResponse = async () => { 
        let email = {
            "email": emailRef.current.value
        }
        await axios.post(Api.API_FORGOTPASS, querystring.stringify(email), Helper.headers)
        .then(res => {
            //console.log(res)
            setLoading(false)
            setSidebar(true)
            setSidebarStatus({
                type: 'Success',
                title: res.data.messageSuccess.title,
                text: res.data.messageSuccess.text
            })
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.data.messageError) {
                    setLoading(false)
                    setSidebar(true)
                    setSidebarStatus({
                        type: 'messageError',
                        title: error.response.data.messageError.title,
                        text: error.response.data.messageError.text
                    })
                }
            } 
        })
    }

    return (
        <>
            {    loading ? ( 
                <Loading/>
            ) : (
                <>
                    <div className="card-forgot-pass-wrapper">
                        <Card className="card-forgot-pass-form">
                            <Card.Body>
                                <div className="title-wrapper">
                                    <h2 className="title">{globalTranslates['Forgot_password']}</h2>
                                    <Link to="/login">
                                        <FontAwesomeIcon  className="close-icon" icon={faTimes}/>  
                                    </Link>
                                </div>
                                <Form className="login-form">
                                    <Form.Group id="email">
                                        <Form.Label>{globalTranslates['Email']}</Form.Label>
                                        <FontAwesomeIcon className="form-icon" icon={faUserCircle}/>
                                        <Form.Control type="email" ref={emailRef}></Form.Control>
                                        <span className="error-msg">{errorEmail && errorEmail}</span>
                                    </Form.Group>
                                    <Button className="w-100 green-button"  onClick={handleValidation}>{globalTranslates['Send_email']}</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                        { sidebar ? (
                            <div className="card-sidebar-wrapper">
                                <SidebarForgotPass {...sidebarStatus}/>
                            </div>
                        ) : ('')}
                    </div>
                </>
            )}
        </>
    )
}
