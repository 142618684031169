import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import DefaultBackCard from '../../../../../content/card-back.png'
import './style.css'
export default function BackCard (props) {
    const [cardSettings] = useState(props)
    const card = cardSettings['cardUserList']
    const hideQrCode = card['hideQRCode'] != null && card['hideQRCode']
    const hideDisclaimerCardBack = card['hideDisclaimerCardBack'] != null && card['hideDisclaimerCardBack']
    return (
        <div className="back-card">
            <div className="image-background" style={{backgroundImage: `url(${card['back_img'] !== null ? card['back_img'] : DefaultBackCard})`}}>
                <div className="button-prev">
                    {
                        props.index === 0 ? ('') : (
                            <div className="icon-wrapper">
                                <FontAwesomeIcon  className="sync-icon" icon={faSyncAlt}/>  
                            </div>
                        )
                    }
                </div>

                {
                    hideQrCode ? ('') : (
                        <figure>
                            <img className="image-qr-code" alt="back-card" src={`${card['club_qrcode'] !== null ? card['club_qrcode'] : ''}`}/>
                        </figure>
                    )
                }
                {
                    hideDisclaimerCardBack ? ('') : (
                        <div className="card-content-wrapper">
                            <div className="card-content">
                                <div className="signature-wrapper">
                                    <span>Issued By / Délivré Par / Herausgegeben von:</span>
                                    <small>I agree to the conditions issued with the card (CCI).</small>
                                    <small>J’accepte les conditions liées à la carte (CCI).</small>
                                    <small>Ich bin mit den Bedingungen der Karte (CCI) einverstanden.</small>
                                    <span className="signature">Signature:_______________________</span>         
                                </div>
                                <div className="card-footer-info">
                                    <small>Fédération Internationale de Camping, Caravanning et Autocaravaning A.I.S.B.L. (F.I.C.C.)</small>
                                    <small>| Rue Belliard 20 BE - 1040 Bruxelles</small>
                                    <small>| www.ficc.org</small>
                                    <small>| info@ficc.org</small>
                                </div>
                            </div>
                        </div>
                    )
                }
                
            </div>
        </div>
    )
}