import React, { useContext } from 'react'
import { globalSettings } from '../../../../context/global'
import SwipeIcon from '../../../../content/svg/swipe-icon.svg'
import './style.css'

export default function Swipe() {
    const global = useContext(globalSettings)
    const globalTranslates = global.globalValue.Translates['SwipeScreen']
    return ( 
      <div className="swipe-screen">
        <figure>
          <img src={SwipeIcon} alt="swipe-icon" />  
          <legend>
            <h2>{globalTranslates['Please_rotate_device']}</h2>
            <span>{globalTranslates['App_support_portrait']}</span>
          </legend>
        </figure> 
      </div>
    )
}