import React, { useState, useEffect, useContext } from 'react'
import { globalSettings } from '../../../../context/global'
import UserNav from '../UserNav/UserNav'
import Loading from '../../../Helpers/Screens/Loading/Loading'
import Accordion from '../../../Helpers/Screens/Accordion/Accordion'
import Api from '../../../../context/api'
import axios from 'axios'

export default function UserHelp(props) {
    const global = useContext(globalSettings)
    const lang = global.globalValue.lang
    const [loading, setLoading] = useState(true)
    const [helpList, setHelpList] = useState([])
    const [accordionStatus] = useState({
        title: '', 
        body: '',
        isCustomIcon: false,
        customIcon: '',
        isCollapse: true
        
    })
    
    // Component Update State
    useEffect(() => {
        // Handle Help Settings
        const fecthHelpSettings = async () => { 
            await axios.get(`${Api.API_HELP}?lang=${lang}`)
            .then(res => {
                setHelpList(res.data)
            })
            .then(() => {
                setLoading(false)
            })
            .catch(error => {
                //console.log(error)
            })
        }
        fecthHelpSettings()
    }, [helpList])
    

    return (
        <>
            <UserNav {...props}/>
            {   loading ? (
                <Loading/>
            ) : (
                <div className="accordion-wrapper">
                    {helpList.map((item) => (
                        <Accordion {...accordionStatus}
                            key={item.id} 
                            title={item.title} 
                            body={item.body} 
                        />
                    ))}
                </div>
            )}
        </>
    )
}
