import React, { useContext, useState } from 'react'
import { globalSettings } from '../../../context/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import Helper from '../../../context/helper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation} from 'swiper'
import './style.css'

/* Apagar apos integrar */
import IMAGE_EXAMPLE_SMALL from '../../../content/list-item-small-no-image.png'
import IMAGE_EXAMPLE_MEDIUM from '../../../content/list-item-medium.jpg'
SwiperCore.use([ Pagination, Navigation])

export default function MapList(props) {

    const global = useContext(globalSettings)

    const globalTranslates = global.globalValue.Translates['Map']

    const [show, setShow] = useState(true)
    let campSites = props.campsites;

    //console.log("Map List properties");
    //console.log(props);

    // Handle Closest Markers

    const getClosestCampsite = (user_location, excluded_campsites) => {


        const rad = (x) => {return x*Math.PI/180;}

        var lat = user_location.lat;
        var lng = user_location.lng;

        var R = 6371; // radius of earth in km
        var distances = [];
        var closest = -1;

        //console.log(excluded_campsites);
        
        props.campsites.forEach(function(location,i) {

            var mlat = location.latitude;
            var mlng = location.longitude;

            var dLat  = rad(mlat - lat);
            var dLong = rad(mlng - lng);
            var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
            var d = R * c;
            distances[i] = d;

            if ( (closest === -1 || d < distances[closest]) && !excluded_campsites.includes(location.id) ) {
                closest = i;
            }

        });

        //console.log('closest: '+props.campsites[closest].name);
        return props.campsites[closest];

    }

    const findClosestCampsites = (user_location, number_items = 4) => {

        //console.log('find_closest_markers');   
        
        var campsites_near_ids = [],
            campsites_near = [];
        
        for (var index = 0; index < number_items; index++) {
            let campsite = getClosestCampsite(user_location, campsites_near_ids);
            campsites_near.push(campsite);
            campsites_near_ids.push(campsite.id);
            //console.log(index);
        }

        //console.log(campsites_near)

        return campsites_near;
        
    }

    if(props.listType === 'near'){
        campSites = findClosestCampsites(props.defaultLocation);
    }

    //console.log(campSites)
  
    return (
        <div className="map-list" data-show={show ? ('true') : ('false') }>
            <div className="open-close-wrapper" onClick={() => Helper.toggleVisiblity(show,setShow)}>
                {
                    show ? (<FontAwesomeIcon className="icon" icon={faChevronDown}/>) : (<FontAwesomeIcon className="icon" icon={faChevronUp}/>)
                }
                {
                    show ? (
                        props.listType !== 'search' ? (
                            <>
                                <div className="map-list-near">
                                <Swiper  
                                    slidesPerView={1}
                                    pagination={true}
                                    onSlideChange={(index) => '' /*console.log(index)*/ }
                                    navigation={false}
                                    >
                                    <h2>{globalTranslates['Campsites_near_you']}</h2>
                                    {
                                        campSites.map((campsite) => (
                                            <SwiperSlide onClick={()=>{props.setDetail(campsite)}} >
                                                <figure>
                                                    {campsite.hs_discount !== '' && campsite.hs_discount !== '0' && campsite.hs_discount !== null ? (
                                                    <>
                                                        <legend className="discount-wrapper green">
                                                        {campsite.hs_discount == 'Accept CCI' ? (
                                                                <><span>{`${campsite.hs_discount}`}</span></>):<><span>{`-${campsite.hs_discount}%`}</span></>}
                                                        </legend>
                                                    </>
                                                     ):''}
                                                     {campsite.ls_discount !== '' && campsite.ls_discount !== '0' && campsite.ls_discount !== null ? (
                                                    <>
                                                        <legend className="discount-wrapper blue">
                                                        {campsite.ls_discount == 'Accept CCI' ? (
                                                                <><span>{`${campsite.ls_discount}`}</span></>):<><span>{`-${campsite.ls_discount}%`}</span></>}
                                                        </legend>
                                                    </>
                                                     ):''}
                                                     {campsite.images !== '' && campsite.images !== null && campsite.images.length ? (
                                                         <>
                                                         <img width="60" height="60" src={campsite.images[0]}/>
                                                         </>
                                                     ):(
                                                        <>
                                                        <img width="60" height="60" src={IMAGE_EXAMPLE_SMALL}/>
                                                        </>
                                                     )}
                                                </figure>
                                                <figcaption>{campsite.name}</figcaption>
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div>
                            </>
                        ) : (
                            <div className="map-list-search">
                                <h2>{campSites.length} {globalTranslates['Results']}</h2>
                                <ul className="map-list-search-results">
                                    {
                                        campSites.map((campsite) => (
                                            <>
                                                <li onClick={()=>{props.setDetail(campsite)}} >
                                                    <figure>
                                                        {campsite.hs_discount !== '' && campsite.hs_discount !== '0' && campsite.hs_discount !== null ? (
                                                        <>
                                                            <legend className="discount-wrapper green">
                                                            {campsite.hs_discount == 'Accept CCI' ? (
                                                                <><span>{`${campsite.hs_discount}`}</span></>):<><span>{`-${campsite.hs_discount}%`}</span></>}
                                                            </legend>
                                                        </>
                                                        ):''}
                                                        {campsite.ls_discount !== '' && campsite.ls_discount !== '0' && campsite.ls_discount !== null ? (
                                                        <>
                                                            <legend className="discount-wrapper blue">
                                                            {campsite.ls_discount == 'Accept CCI' ? (
                                                                <><span>{`${campsite.ls_discount}`}</span></>):<><span>{`-${campsite.ls_discount}%`}</span></>}
                                                            </legend>
                                                        </>
                                                        ):''}
                                                        {campsite.images !== '' && campsite.images !== null && campsite.images.length ? (
                                                            <>
                                                            <img width="60" height="60" src={campsite.images[0]}/>
                                                            </>
                                                        ):(
                                                            <>
                                                            <img width="60" height="60" src={IMAGE_EXAMPLE_SMALL}/>
                                                            </>
                                                        )}
                                                    </figure>
                                                    <figcaption>{campsite.name}</figcaption>
                                                    <small>{campsite.country}</small>
                                                </li>
                                            </>
                                        ))
                                    }
                                </ul>
                            </div>  
                        )
                    ) : 
                    ('')
                }
            </div>
        </div>
    )
}
