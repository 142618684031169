import React, { useState, useEffect, useContext } from 'react'
import { globalSettings } from '../../../../context/global'
import UserNav from '../UserNav/UserNav'
import Accordion from '../../../Helpers/Screens/Accordion/Accordion'
import Loading from '../../../Helpers/Screens/Loading/Loading'
import { faMapMarkerAlt, faPhone, faEnvelope, faBrowser } from '@fortawesome/pro-light-svg-icons'
import Api from '../../../../context/api'
import axios from 'axios'

export default function UserDetails(props) {
    const global = useContext(globalSettings)
    const user =  global.globalValue.user
    const globalTranslates = global.globalValue.Translates['Profile']
    const [loading, setLoading] = useState(true);
    const [clubs, setClubs] = useState([])
    let clubListItems = clubs.clubList 
    const [accordionStatus] = useState({
        title: '', 
        body: '',
        isCustomIcon: true,
        customIcon: '',
        isCollapse: '',
        isLink: {
            type: '',
            isLinkTitle: false 
        }
    })
    const [paramsSettings] = useState({
        clubId: user.clubId,
    })
    
    // Component Update State
    useEffect(() => {
        fecthClubSettings()
    }, [])

    // Handle Club Settings
    const fecthClubSettings = async () => { 
        await axios.get(`${Api.API_CLUB}?clubId=${paramsSettings.clubId}`)
        .then(res => {
            setClubs(res.data)
        })
        .then(() => {
            setLoading(false)
        })
        .catch(error => {
            //console.log(error)
        })
    }

    return (
        <>
            <UserNav {...props}/>
            {   loading ? (
                <Loading/>
            ) : (
                <div className="accordion-wrapper">
                    <Accordion {...accordionStatus}
                        title={globalTranslates['Address']}
                        body={clubListItems.address}
                        customIcon={faMapMarkerAlt}
                        isCustomIcon={true}
                        isCollapse={true}
                        isLink={{
                            type: '',
                            isLinkTitle: false 
                        }}
                    />
                    <Accordion {...accordionStatus}
                        title={clubListItems.phone}
                        body=""
                        customIcon={faPhone}
                        isCustomIcon={true}
                        isCollapse={false}
                        isLink={{
                            type: 'tel:',
                            isLinkTitle: true 
                        }}
                    />
                     <Accordion {...accordionStatus}
                        title={clubListItems.email}
                        body=""
                        customIcon={faEnvelope}
                        isCustomIcon={true}
                        isCollapse={false}
                        isLink={{
                            type: 'mailto:',
                            isLinkTitle: true 
                        }}
                        />
                     <Accordion {...accordionStatus}
                        title={clubListItems.website}
                        body=""
                        customIcon={faBrowser}
                        isCustomIcon={true}
                        isCollapse={false}
                        isLink={{
                            type: 'https://',
                            isLinkTitle: true 
                        }}
                    />

                </div>
            )}
        </>
    )
}
