import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/pro-light-svg-icons'
import './style.css'

export default function Accordion({ title, body, isCustomIcon, customIcon, isCollapse, isLink }) {
    const [isOpen, setIsOpen] = useState(false)
    let [stateSuccess] = useState({ title, body, isCustomIcon, customIcon, isCollapse , isLink});
    
    return (
        <div className="accordion">
            {
                stateSuccess.isCustomIcon ? (
                    <>

                        {
                            !stateSuccess.isLink.isLinkTitle ? (
                                
                                <div onClick={() => setIsOpen(!isOpen)} className={`accordion-title ${isOpen ? "open" : ""}`}>
                                    <FontAwesomeIcon  className="custom-icon" icon={customIcon}/>
                                    <h2>{title}</h2>
                                </div>

                            ) : (
                                
                                <div className={`accordion-title ${isOpen ? "open" : ""}`}>
                                    <FontAwesomeIcon  className="custom-icon" icon={customIcon}/>
                                    <a target="_blank" rel="noopener noreferrer" href={`${stateSuccess.isLink.type}${title}`}>{title}</a>
                                </div>

                            )
                        }

                        <div className={`accordion-item ${stateSuccess.isCollapse ? "is-collapsed" : ""} ${!isOpen ? "collapsed" : ""}`}>
                            <div className="accordion-content" dangerouslySetInnerHTML={{__html: body}}></div>
                        </div>
                    </>
                
                ) : (
                    
                    <>
                        <div onClick={() => setIsOpen(!isOpen)} className={`accordion-title ${isOpen ? "open" : ""}`}>
                            <FontAwesomeIcon  className="icon open-icon" icon={faPlusCircle}/>  
                            <FontAwesomeIcon  className="icon close-icon" icon={faMinusCircle}/>
                            <h2>{title}</h2>
                        </div>
                        <div className={`accordion-item ${stateSuccess.isCollapse ? "is-collapsed" : ""} ${!isOpen ? "collapsed" : ""}`}>
                            <div className="accordion-content" dangerouslySetInnerHTML={{__html: body}}></div>
                        </div>
                    </>
                )
            }
        </div>
    )
}
  