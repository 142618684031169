import React, { useState, useEffect, useContext } from 'react'
import { globalSettings } from './../../../../context/global'
import FrontCard from './FrontDigitalCard/FrontDigitalCard'
import BackCard from './BackDigitalCard/BackDigitalCard'
import UserNav from '../UserNav/UserNav'
import Loading from '../../../Helpers/Screens/Loading/Loading'
import Api from '../../../../context/api'
import axios from 'axios'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFlip, Pagination, Navigation} from 'swiper'
import './style.css'

SwiperCore.use([ EffectFlip, Pagination, Navigation])

export default function UserCard(props) {

    const global = useContext(globalSettings)
    const user =  global.globalValue.user
    const [paramsSettings] = useState({
        cardNumber: user.cardNumber,
        memberId: user.memberId,
        cardType: user.cardType
    })
    const [cardSettings, setCardSettings] = useState('')
    const [loading, setLoading] = useState(true)
    const [index, setIndex] = useState(0)
    
    // Component Update State
    useEffect(() => {
        // Handle Fecth Card Settings
        const fecthCardSettings = async () => { 
            await axios.get(`${Api.API_CARD}?cardNumber=${paramsSettings.cardNumber}&memberId=${paramsSettings.memberId}&cardType=${paramsSettings.cardType}`)
            .then(res => {
                setCardSettings(res.data)
            })
            .then(() => {
                setLoading(false)
            })
            .catch(error => {
                //console.log(error)
            })
        }
        fecthCardSettings()
    }, [cardSettings])
    
    
    return (
        <>
            {   loading ? (
                <Loading/>
            ) : (
            <div className="digital-card">
                <Swiper  
                    effect="flip"
                    slidesPerView={1}
                    pagination={false}
                    onSlideChange={(index) => setIndex(index.activeIndex)}
                    navigation={{ nextEl: '.button-next', prevEl: '.button-prev' }}
                    >
                    <SwiperSlide><FrontCard {...cardSettings}  index={index}></FrontCard></SwiperSlide>
                    <SwiperSlide><BackCard {...cardSettings}  index={index}></BackCard></SwiperSlide>
                </Swiper>
            </div>
            )}

        </>
    )
}

