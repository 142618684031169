const Helper = {
    // Filter Array and remove duplicates
    filterArrays: (arr, property, newArray) => {
        var uniqueArray = []
        for (let index in arr) {
            newArray.push(arr[index][`${property}`])
        }
        for(let i=0; i < newArray.length; i++){
            if(uniqueArray.indexOf(newArray[i]) === -1) {
                uniqueArray.push(newArray[i])
            }
        }

        uniqueArray = uniqueArray.filter(function (a) {
            return a != null && a != undefined && a != '';
        });
        
        uniqueArray = uniqueArray.sort((a, b) =>
            a.localeCompare(b)
        );

        return uniqueArray
    },
    // Add to Object
    addToObject: (obj, key, value, index)  => {
        let temp = {}
        let i = 0
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (i === index && key && value) {
                    temp[key] = value
                }
                temp[prop] = obj[prop]
                i++
            }
        }
        if (!index && key && value) {
            temp[key] = value
        }
        return temp
    },
    // Toggle Visiblity
    toggleVisiblity: (variable, setter ) => {
        setter(variable ? false : true)
    },
    // Content-Type Headers
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    // Handle Object
    handleObject: (obj, visited = new WeakSet()) => {
        if (visited.has(obj)) {
            return {}; // skip already visited object to prevent cycles
        }
        visited.add(obj); // add the current object to the visited set
    
        var result = {}, _tmp;
        for (var i in obj) {
            try {
                // enabledPlugin is too nested, also skip functions
                if (i === 'enabledPlugin' || typeof obj[i] === 'function') {
                    continue;
                } else if (typeof obj[i] === 'object') {
                    // get props recursively
                    _tmp = this.handleObject(obj[i], visited);
                    // if object is not {}
                    if (Object.keys(_tmp).length) {
                        result[i] = _tmp;
                    }
                } else {
                    // string, number or boolean
                    result[i] = obj[i];
                }
            } catch (error) {
                // handle error, you can log it here if needed
                // console.error('Error:', error);
            }
        }
        return result;
    }

}

export default Helper