import React, { useState, useEffect, useContext } from "react"
import { globalSettings } from '../../../context/global'
import {Form, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faEyeSlash } from '@fortawesome/pro-light-svg-icons'
import Helper from './../../../context/helper'
import './style.css'

export default function SignupStepOne (props) {
    const global = useContext(globalSettings)
    const globalTranslates = global.globalValue.Translates['SignUp']
    const globalTranslatesErrors = global.globalValue.Translates['ErrorMessages']
    const [error, setError] = useState('')
    const [errorName, setErrorName] = useState('')
    const [errorEmail, setErrorEmail] = useState('')
    const [errorPassword, setErrorPassword] = useState('')
    const [errorPasswordConfirm, setErrorPasswordConfirm] = useState('')
    const [errorPasswordQuestion, setErrorPasswordQuestion] = useState('')
    const [errorPasswordAnswer, setErrorPasswordAnswer] = useState('')
    const [passwordShown, setPasswordShown] = useState(false)
    
    // Component Update State
    useEffect(() => {
        setError('')
        setErrorName('')
        setErrorEmail('')
        setErrorPassword('')
        setErrorPasswordConfirm('')
        setErrorPasswordQuestion('')
        setErrorPasswordAnswer('')
    }, [
        props.state.name,
        props.state.email, 
        props.state.password, 
        props.state.passwordConfirm, 
        props.state.passwordQuestion, 
        props.state.passwordAnswer
    ])

    // Handle Validation Form
    const handleValidation = () => {
        let patternEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let patternPassword = new RegExp(/^.{6,}$/)
        if (props.state.name === "" && props.state.email === "" &&props.state.password === "" &&props.state.passwordConfirm === "" &&props.state.passwordQuestion === "" &&props.state.passwordAnswer === "" ) {
            setError(globalTranslatesErrors['Required_filed'])
        } else if (props.state.email === "") {
            setErrorEmail(globalTranslatesErrors['Please_write_your_email'])
        } else if (props.state.password === "" ) {
            setErrorPassword(globalTranslatesErrors['Please_write_your_password'])
        } else if (props.state.passwordConfirm  === "" ) {
            setErrorPasswordConfirm(globalTranslatesErrors['Please_confirm_your_password'])
        } else if (props.state.password !== props.state.passwordConfirm) {
            setErrorPasswordConfirm(globalTranslatesErrors['Password_do_not_match'])
        } else if (props.state.passwordQuestion === "" ) {
            setErrorPasswordQuestion(globalTranslatesErrors['Please_write_security_question'])
        } else if (props.state.passwordAnswer === "" ) {
            setErrorPasswordAnswer(globalTranslatesErrors['Please_write_security_answer'])
        } else if (props.state.name === "") {
            setErrorName(globalTranslatesErrors['Please_write_your_name'])
        } else if(!patternPassword.test(props.state.password)) {
            setErrorEmail(globalTranslatesErrors['Minimum_6_digits'])
        } else if(!patternEmail.test(props.state.email)) {
            setErrorEmail(globalTranslatesErrors['Please_write_valid_email'])
        } else {
            handleSubmit()
        }
    }

    // Handle Submit Form to Next Step
    const handleSubmit = () => {
        props.next()
    }

    return (
        <>
            <div className="title-wrapper">
                <h2 className="title">{globalTranslates['Sign_up']}</h2>
                <Link to="/login">
                    <FontAwesomeIcon  className="close-icon" icon={faTimes}/>  
                </Link>
            </div>
            <Form.Group id="name" className={error ? "error" : errorName ? "error" : "" }>
                <Form.Label>{globalTranslates['Name']}</Form.Label>
                <Form.Control value={props.getState('name')} onChange={props.handleChange} name="name" type="text" required></Form.Control>
                <span className="error-msg">{error && error}</span>
                <span className="error-msg">{errorName && errorName}</span>
            </Form.Group>
            <Form.Group id="email" className={error ? "error" : errorEmail ? "error" : "" }>
                <Form.Label>{globalTranslates['Email']}</Form.Label>
                <Form.Control value={props.getState('email')} onChange={props.handleChange} name="email" type="email" required></Form.Control>
                <span className="error-msg">{error && error}</span>
                <span className="error-msg">{errorEmail && errorEmail}</span>
            </Form.Group>
            <Form.Group id="password" className={error ? "error" : errorPassword ? "error" : "" }>
                <Form.Label>{globalTranslates['Password']}</Form.Label>
                <Form.Control value={props.getState('password')} onChange={props.handleChange} name="password" type={passwordShown ? "text" : "password"} required></Form.Control>
                <FontAwesomeIcon onClick={() => Helper.toggleVisiblity(passwordShown,setPasswordShown)} className="form-icon right-align" icon={faEyeSlash}/>
                <span className="error-msg">{error && error}</span>
                <span className="error-msg">{errorPassword && errorPassword}</span>
            </Form.Group>
            <Form.Group id="password-confirm" className={error ? "error" : errorPasswordConfirm ? "error" : "" }>
                <Form.Label>{globalTranslates['Confirm_password']}</Form.Label>
                <Form.Control value={props.getState('passwordConfirm')} onChange={props.handleChange} name="passwordConfirm" type={passwordShown ? "text" : "password"} required></Form.Control>
                <FontAwesomeIcon onClick={() => Helper.toggleVisiblity(passwordShown,setPasswordShown)} className="form-icon right-align" icon={faEyeSlash}/>
                <span className="error-msg">{error && error}</span>
                <span className="error-msg">{errorPasswordConfirm && errorPasswordConfirm}</span>
            </Form.Group>
            <Form.Group id="password-question" className={error ? "error" : errorPasswordQuestion ? "error" : "" }>
                <Form.Label>{globalTranslates['Password_question']}</Form.Label>
                <Form.Control  value={props.getState('passwordQuestion')} onChange={props.handleChange} name="passwordQuestion" type="text" required></Form.Control>
                <span className="error-msg">{error && error}</span>
                <span className="error-msg">{errorPasswordQuestion && errorPasswordQuestion}</span>
            </Form.Group>
            <Form.Group id="password-answer" className={error ? "error" : errorPasswordAnswer ? "error" : "" }>
                <Form.Label>{globalTranslates['Password_answwer']}</Form.Label>
                <Form.Control value={props.getState('passwordAnswer')} onChange={props.handleChange} name="passwordAnswer" type="text" required></Form.Control>
                <span className="error-msg">{error && error}</span>
                <span className="error-msg">{errorPasswordAnswer && errorPasswordAnswer}</span>
            </Form.Group>
            <Button className="blue-button" onClick={handleValidation}>{globalTranslates['Next']}</Button>
            <div className="footer-wrapper">
                <div className="counter">
                    <span className="active">{props.current}</span>
                    <span> / </span>
                    <span className="total">2</span>
                </div>
            </div>
        </>
    )
}